import styles from './PreviewNewHeader.module.css';
import { ReactComponent as SoulMeLogo } from '../../../assets/soulMeLogo.svg';

const PreviewNewHeader = ({ img, name, logo, subtitle, bg }) => {
  return (
    <div
      style={{
        borderRadius: '10px',
        background: bg,
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        margin: '30px 20px 0px 20px'
      }}>
      <div className={styles.headerIcons}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
          <img src={img} width={50} height={50} style={{ objectFit: 'cover' }} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <span style={{ color: 'white', fontWeight: 600, fontSize: 16 }}>{name}</span>
            {subtitle && (
              <span style={{ color: 'white', fontWeight: 400, fontSize: 10 }}>{subtitle}</span>
            )}
          </div>
        </div>
        <span>
          {!logo && <SoulMeLogo width={40} height={40} />}
          {logo && <img src={logo} width={40} height={40} style={{ objectFit: 'cover' }} />}
        </span>
      </div>
    </div>
  );
};

export default PreviewNewHeader;
