import { useEffect, useState, lazy, Suspense } from 'react';
import './index.css';
import './styles/global.css';
import 'react-circular-progressbar/dist/styles.css';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
const Home = lazy(() => import('./views/components/HomeScreen/HomeScreen'));
const RecordingScreen = lazy(() => import('./views/components/RecordingScreen/RecordingScreen'));
const VideoPreview = lazy(() => import('./views/components/VideoPreview/VideoPreview'));
const CountDownPage = lazy(() => import('./views/components/CountDownPage/CountDownPage'));
const AudioPreview = lazy(() => import('./views/components/AudioPreview/AudioPreview'));
const SuccessScreen = lazy(() => import('./views/components/SuccessScreen/SuccessScreen'));
const AttachmentScreen = lazy(() => import('./views/components/AttachmentScreen/AttachmentScreen'));
const VideoAttachScreen = lazy(() =>
  import('./views/components/RecordingScreen/VideoAttachScreen/VideoAttachScreen')
);
const AudioAttachScreen = lazy(() =>
  import(
    './views/components/RecordingScreen/AudioRecordScreen/AudioAttachRecordScreen/AudioAttachRecordScreen'
  )
);
const UploadScreen = lazy(() => import('./views/components/AttachmentScreen/AttachmentScreen'));
const AudioRecordScreen = lazy(() =>
  import('./views/components/RecordingScreen/AudioRecordScreen/AudioRecordScreen')
);
const Permission = lazy(() => import('./views/components/Permission'));
const AR_INTEGRATION_TEST = lazy(() => import('./views/AR_INTEGRATION_TEST'));
import PermissionsPopup from './views/components/PermissionsDialog/PermissionsPopup';
import SizeErrorPopup from './views/components/SizeErrorPopup/SizeErrorPopup';
import Bahdasarian from './views/components/Bahdasarian';
import EnvSolution from './views/components/EnvSolution';

const App = () => {
  document.body.style.overflow = 'hidden';

  const [showPermissionErrorDialog, setShowPermissionErrorDialog] = useState(false);
  const [showFileSizeError, setShowFileSizeError] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  console.log('using settings', process.env);
  useEffect(() => {
    const videoId = searchParams.get('videoId');
    if (videoId === 'f03e17a3-f475-43fe-bc9b-7cd0d46a6157') {
      console.log('navigating');
      navigate(
        '/video?url=https://soulme-videos-store.s3.eu-central-1.amazonaws.com/videos/972e28fa-d557-4e01-b404-2194255a1f00/1687265797972e28fa-d557-4e01-b404-2194255a1f00.mp4'
      );
    }

    if (videoId === 'e5166e36-d79b-4f09-b072-6c38fe1ad27d') {
      console.log('navigating');
      window.location.href =
        'https://www.figma.com/proto/DsI4Hn8tnm0mK2ZOEVIAa1/SoulMe?page-id=4669%3A5767&type=design&node-id=4678-7882&viewport=-736%2C1045%2C1&t=mCJIi4n84QLPSSaD-1&scaling=min-zoom&starting-point-node-id=4669%3A7331&show-proto-sidebar=1';
      return;
    }

    window.addEventListener('permission-changed-event', () => {
      setShowPermissionErrorDialog(true);
    });
    window.addEventListener('size-error', () => {
      setShowFileSizeError(true);
    });
  }, []);

  const location = useLocation();

  if (location.pathname === '/' && location.search === '') {
    window.location.href = 'https://soulme.net/';
    return null;
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Suspense fallback={''}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/record/" element={<RecordingScreen />} />
          <Route path="/video" element={<VideoPreview />} />
          <Route path="/countdown" element={<CountDownPage />} />
          <Route path="/audio" element={<AudioPreview />} />
          <Route path="/success" element={<SuccessScreen />} />
          <Route path="/attach" element={<AttachmentScreen />} />
          <Route path="/attach/video" element={<VideoAttachScreen />} />
          <Route path="/attach/audio" element={<AudioAttachScreen />} />
          <Route path="/upload" element={<UploadScreen />} />
          <Route path="/test" element={<AudioRecordScreen />} />
          <Route path="/faq/permissions" element={<Permission />} />
          <Route path="/artest" element={<AR_INTEGRATION_TEST />} />
          <Route path="/Bahdasarian" element={<Bahdasarian />} />
          <Route path="/envsolution" element={<EnvSolution />} />
        </Routes>
      </Suspense>
      <PermissionsPopup open={showPermissionErrorDialog} />
      <SizeErrorPopup open={showFileSizeError} onClose={() => setShowFileSizeError(false)} />
    </div>
  );
};
export default App;
