import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#146DBF'
    },
    secondary: {
      main: '#000000'
    },
    text: {
      main: '#FFFFFF',
      light: '#AEAEAE',
      dark: '#6B6B6B',
      darkLight: '#636363',
      darker: '#424242'
    },
    button: {
      primary: {
        textColor: '#146DBF',
        background: '#EDEDED'
      },
      secondary: {
        textColor: '#FFFFFF',
        background: '#E21E1E'
      },
      disabled: {
        textColor: '#6B6B6B',
        background: '#242424'
      }
    }
  },
  typography: {
    button: {
      fontSize: '19px',
      fontFamily: 'SFUIText-Medium',
      textTransform: 'initial'
    },
    caption: {
      fontSize: '24px',
      fontWeight: 800,
      fontFamily: 'SFUIText-Bold'
    },
    body1: {
      fontSize: '21px',
      fontWeight: 700,
      fontFamily: 'SFUIText-Medium'
    },
    bolder: {
      fontSize: '24px',
      fontWeight: 800,
      fontFamily: 'SFUIText-Bold'
    }
  }
});

theme.components = {
  MuiButton: {
    styleOverrides: {
      contained: {
        padding: '14px 68px',
        background: theme.palette.button.primary.background,
        color: theme.palette.button.primary.textColor,
        borderRadius: '32px',
        border: 'none',
        outline: 'none',
        boxShadow: 'none',
        '&:hover': {
          background: theme.palette.button.primary.background,
          color: theme.palette.button.primary.textColor
        }
      },
      text: {
        color: theme.palette.text.main,
        fontWeight: 700,
        textDecoration: 'underline',
        fontSize: '18px',
        padding: 0,
        '&:hover': {
          textDecoration: 'underline'
        }
      }
    }
  }
};

export default theme;
