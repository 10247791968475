import { useEffect, useRef, useState } from 'react';
import styles from './Certificate.module.css';

const Certificate = ({ isCertificateOpen, setIsCertificateOpen, img }) => {
  const imgRef = useRef(null);
  const [isAnimationDown, setIsAnimationDown] = useState(false);
  const isMountedRef = useRef(false); // Ref to check if component is mounted

  useEffect(() => {
    isMountedRef.current = true; // Set mounted to true when component mounts

    const handler = (e) => {
      if (!imgRef.current.contains(e.target) && e.target.id !== 'certButton') {
        // Only update state if the component is mounted
        if (isMountedRef.current) {
          if (isCertificateOpen) {
            setIsAnimationDown(true);
            setIsCertificateOpen(false);
          }
        }
      }
    };

    document.addEventListener('click', handler);

    return () => {
      isMountedRef.current = false; // Set mounted to false on unmount
      document.removeEventListener('click', handler);
    };
  }, [isCertificateOpen, setIsCertificateOpen]); // Add dependencies

  return (
    <>
      <div
        ref={imgRef}
        className={`${styles.certWrapper} ${styles.initial} ${
          isCertificateOpen ? styles.animateUp : `${isAnimationDown && styles.animateDown}`
        }`}>
        <img className={styles.certImg} src={img} />
      </div>
      {isCertificateOpen && <div className={styles.bgDiv}></div>}
    </>
  );
};

export default Certificate;
