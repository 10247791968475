import { Input, Box, styled, Typography } from '@mui/material';

export const StyledBody = styled(Box)(() => ({
  width: '320px',
  height: '330px',
  background: 'linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.6) 100%)',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '30px 0'
}));

export const StyledText = styled(Typography)(() => ({
  fontSize: '19px',
  lineHeight: '28px',
  textAlign: 'center',
  color: '#fff',
  fontWeight: '500'
}));

export const StyledActionButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '45px'
}));

export const StyledActionButton = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px'
}));
export const StyledActionButtonInput = styled(Input)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px'
}));
