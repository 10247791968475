import { Button, Dialog } from '@mui/material';
import { memo } from 'react';
import { StyledBody, StyledText } from './RecordOrAudioPopUp.styled';
import { ReactComponent as NoCamera } from '../../../assets/permission/no_camera.svg';
import { ReactComponent as NoMicrophone } from '../../../assets/permission/no_microphone.svg';

const PermissionsPopup = ({ handleClose, open }) => {
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          width: '320px',
          height: '500px',
          background: 'transparent',
          boxShadow: 'none'
        }
      }}
      hideBackdrop>
      <StyledBody style={{ height: '400px' }}>
        <div>
          <NoMicrophone style={{ fontSize: '60px', color: 'white' }} />
          <NoCamera style={{ fontSize: '60px', color: 'white' }} />
        </div>

        <StyledText
          variant="body1"
          sx={{
            fontSize: '23px',
            fontWeight: '700',
            margin: '25px 0 35px'
          }}>
          Allow Camera and Microphone Access
        </StyledText>
        <StyledText sx={{ padding: '10px', fontSize: '19px', fontWeight: '400' }}>
          To be able to record a video or audio, please allow access to your browser's Camera &
          Microphone and Try again.
        </StyledText>
        <Button
          onClick={() => {
            window.location.reload();
          }}
          variant="contained"
          sx={{
            marginBottom: '30px',
            marginTop: '20px',
            padding: '12px 40px 12px 20px',
            textAlign: 'center',
            width: '220px',
            paddingLeft: '35px!important'
          }}>
          Try again
        </Button>
      </StyledBody>
    </Dialog>
  );
};

export default memo(PermissionsPopup);
