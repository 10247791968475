import { useState } from 'react';
import styles from './PreviewPage.module.css';
// import Background from '../Background';
import PreviewNewHeader from '../PreviewHeader';
import PreviewVideo from '../PreviewVideo';
import Certificate from '../Certificate';
import FooterBadge from '../FooterBadge';
import hakob from './assets/hakob.jpg';
import shmn from './assets/shmn.png';
import certificate from './assets/certificate.png';

const EnvSolution = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCertificateOpen, setIsCertificateOpen] = useState(false);

  return (
    <>
      <div className={`${styles.previewBg} ${isExpanded ? styles.topLayer : ''}`}>
        {/* <Background /> */}
        <PreviewNewHeader
          img={hakob}
          name={'Հակոբ Սիմիձյան'}
          logo={shmn}
          subtitle={'Շրջակա Միջավայրի Նախարար'}
          bg={'rgba(0, 0, 0, 0.6)'}
        />
        <PreviewVideo
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          src={
            'https://soulme-videos-store.s3.eu-central-1.amazonaws.com/videos/special/tnki_demo_nosound.mp4'
          }
          mute={true}
        />
      </div>
      <Certificate
        isCertificateOpen={isCertificateOpen}
        setIsCertificateOpen={setIsCertificateOpen}
        img={certificate}
      />
      <FooterBadge
        setIsCertificateOpen={setIsCertificateOpen}
        bg={'var(--Linear, linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.1) 100%))'}
        btnGradient={'linear-gradient(180deg, #3A8A84 28.1%, #36ADA5 80%)'}
      />
    </>
  );
};

export default EnvSolution;
