const FooterBadge = ({ setIsCertificateOpen, bg, btnGradient }) => {
  return (
    <div className="footer" style={{ background: bg }}>
      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <button
          style={{
            background: btnGradient ?? 'linear-gradient(to right, #56C2FF, #0087D6)',
            borderRadius: 8,
            height: 42,
            border: 'none',
            width: 124,
            color: 'white',
            fontWeight: 700
          }}
          onClick={() => {
            setIsCertificateOpen(true);
          }}>
          Certificate
        </button>
      </div>
    </div>
  );
};

export default FooterBadge;
