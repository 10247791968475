import { useRef, useState, useEffect } from 'react';
import styles from './PreviewVideo.module.css';
import { ReactComponent as PlayIcon } from '../../../assets/playIcon.svg';
import { ReactComponent as GoBackIcon } from '../../../assets/goBackIcon.svg';

const PreviewVideo = ({ isExpanded, setIsExpanded, src }) => {
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const handlePlayClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
      if (!isExpanded) {
        setIsExpanded(true);
      }
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const handleVideoEnd = () => {
        video.currentTime = 0.1;
      };

      video.addEventListener('ended', handleVideoEnd);

      return () => {
        video.removeEventListener('ended', handleVideoEnd);
      };
    }
  }, []);

  return (
    <div className={`${styles.videoContainer} ${isExpanded ? styles.expanded : ''}`}>
      <video
        ref={videoRef}
        playsInline
        src={src}
        controls={isExpanded}
        style={{
          borderRadius: isExpanded ? '0' : '9999px',
          width: !isExpanded ? 300 : '100%',
          height: !isExpanded ? 300 : '100vh'
        }}
        onPlaying={() => setIsLoading(false)}
        onLoadedMetadata={() => {
          setIsLoading(false);
          if (videoRef.current) {
            videoRef.current.currentTime = 0.1;
          }
        }}
        preload="metadata"
        onCanPlay={() => setIsLoading(false)}
      />
      {isExpanded && (
        <div
          className={styles.goBack}
          onClick={() => {
            if (videoRef.current) {
              videoRef.current.pause();
            }
            setIsExpanded(false);
          }}>
          <GoBackIcon />
        </div>
      )}
      {!isExpanded &&
        (!isLoading ? (
          <div className={styles.playButton} onClick={handlePlayClick}>
            <div style={{ marginLeft: '4px', marginTop: '4px' }}>
              <PlayIcon width={30} height={30} />
            </div>
          </div>
        ) : (
          <div className={styles.spinnerBox}>
            <div className={styles.spinner} />
          </div>
        ))}
    </div>
  );
};

export default PreviewVideo;
